// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const preocupacao: Topic = {
  topic: 'Preocupação',
  level: 'Intermediate',
  totalQuestions: 16,
  totalScore: 95,
  totalTime: 600,
  questions: [
    {
      question: 'Quando não tenho tempo suficiente para realizar todas as tarefas que tenho, eu não me preocupo.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },

    {
      question: 'Minhas preocupações frequentemente superam meu controle',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Não sou propenso a me preocupar.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Há muitas situações que me causam preocupação.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Eu reconheço que não deveria me preocupar com as coisas, mas não consigo evitar.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Quando estou sob pressão, eu me preocupo muito com as coisas.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Eu estou constantemente preocupado com alguma coisa.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Eu acho fácil deixar de lado as minhas preocupações.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Eu nunca me preocupo com nada.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Quando não há nada que eu possa fazer a respeito de um problema, eu deixo de me preocupar com ele.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Quando não há nada que eu possa fazer a respeito de um problema, eu deixo de me preocupar com ele.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Durante toda a minha vida, tenho estado preocupado com alguma coisa.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Percebo que tenho estado preocupado com várias coisas. ',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Assim que começo a me preocupar, não consigo parar.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Eu passo a maior parte do tempo me preocupando.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    {
      question: 'Eu me preocupo com projetos até que estejam completamente finalizados.',
      choices: [
        { choice: "Nada característico", score: 1 },
        { choice: "Pouco característico", score: 2 },
        { choice: "Característico até certo ponto", score: 3 },
        { choice: "Bastante característico", score: 4 },
        { choice: "Muito característico", score: 5 },
      ],
      type: 'MCQs',
      correctAnswers: [
        'A syntax extension for JavaScript that allows writing HTML-like code in JavaScript',
      ],
      score: 10,
    },
    
    

  ],
}
